import React, { useRef, useState } from "react";
import about_home from "../../assets/about-home.jpg";
import { Link } from "react-router-dom";
import { useLanguage } from "../LanguageContext";
import about_video from "../../assets/videoplayback.mp4";
import Video from "./Video";
import logo2 from "../../assets/transparent-logo.png";
import AdsComponent from "../AdsComponent";

const AboutHome = ({ data }) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };

  return (
    <div className="about-home overflow-hidden" id="#section02">
      <div className="container my-6 ">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 mb-4 mb-lg-0">
            <Video video={data?.video} />
          </div>
          <div className="col-lg-6 col-md-12 mb-4 mb-lg-0">
            <div className="ps-lg-5">
              {/* <h2 className="mb-3">{data.dream}</h2> */}
              <img className="about_dream" src={logo2} alt="Company Logo" />

              <div
                dangerouslySetInnerHTML={{ __html: data?.dream_para }}
                style={{}}
              />
              {/* <p className="color_p mb-2">
               {data.dream_para}
              </p> */}

              {/* Add Component */}
              <AdsComponent />
              <Link to="/aboutUs">
                {" "}
                <button className="about-home-btn">{data?.know_more}</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutHome;
